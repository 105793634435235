import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const tight = definePartsStyle({
  container: {
    borderRadius: 'lg',
    shadow: 'purple-md',
  },
});

const borderedWhite = definePartsStyle({
  container: {
    bgGradient: 'linear(to-b, white, gray.100)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'purple.200',
    shadow: 'purple-md',
  },
});

const flat = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    container: {
      bg: `${c}.300`,
      borderRadius: '20px',
      shadow: '2xl',
      color: 'white',
    },
  };
});

const elevated = definePartsStyle({});

const sizes = {
  md: definePartsStyle({
    container: {
      // borderRadius: '0px',
    },
  }),
  lg: definePartsStyle({
    container: {
      borderRadius: '20px',
      shadow: '2xl',
    },
    header: {
      fontFamily: 'heading',
      fontSize: 'xl',
      fontWeight: 'bold',
      marginX: '10',
      paddingX: '0',
      paddingTop: '7',
      paddingBottom: '5',
      borderBottomColor: 'purple.100',
      borderBottomWidth: '1px',
    },
    body: {
      paddingX: '10',
      paddingY: '8',
    },
    footer: {
      marginX: '10',
      paddingX: '0',
      paddingY: '5',
      borderTopColor: 'purple.100',
      borderTopWidth: '1px',
    },
  }),
};

export const cardTheme = defineMultiStyleConfig({
  sizes,
  variants: {
    flat,
    elevated,
    tight,
    borderedWhite,
  },
  defaultProps: {
    size: 'lg',
    variant: 'elevated',
  },
});
