import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const formErrorColor = cssVar('form-error-color');

const baseStyleText = defineStyle({
  [formErrorColor.variable]: `colors.red.500`,
  _dark: {
    [formErrorColor.variable]: `colors.red.300`,
  },
  color: formErrorColor.reference,
  mt: '2',
  fontSize: 'sm',
  lineHeight: 'normal',
});

const baseStyleIcon = defineStyle({
  marginEnd: '0.5em',
  [formErrorColor.variable]: `colors.red.500`,
  _dark: {
    [formErrorColor.variable]: `colors.red.300`,
  },
  color: formErrorColor.reference,
});

const baseStyle = definePartsStyle({
  text: baseStyleText,
  icon: baseStyleIcon,
});

export const formErrorTheme = defineMultiStyleConfig({
  baseStyle,
});
