import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderTop: 0,

    _first: {
      button: {
        div: {
          borderTop: 0,
        },
      },
    },
    _last: {
      borderBottom: 0,
    },
  },
  button: {
    paddingY: 0,
    fontFamily: 'heading',
    fontWeight: '400',
    paddingX: 4,
    div: {
      borderTop: '1px solid',
      borderTopColor: 'purple.100',
      paddingY: 4,
    },
    svg: {
      color: 'purple.300',
    },
    _hover: {
      background: 'transparent',
      color: 'purple.400',
      svg: {
        color: 'purple.400',
      },
    },
  },
  panel: {
    mx: 4,
    px: 0,
    py: 4,
    borderTop: '1px solid',
    borderTopColor: 'purple.100',
  },
  icon: {
    color: 'purple.300',
  },
});

export const navMenu = definePartsStyle({
  button: {
    paddingX: 7,
  },
  panel: {
    backgroundColor: 'purple.100',
    marginX: 0,
    paddingX: 7,
  },
});

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { navMenu },
});
