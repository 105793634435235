import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    color: 'gray.600',
    fontFamily: 'heading',
    fontSize: 'sm',
  },
  control: {
    borderColor: 'purple.300',
    borderRadius: 4,
    borderWidth: 1,
    _readOnly: {
      backgroundColor: 'purple.100',
      borderColor: 'gray.200',
      color: 'purple.200',
      _hover: {
        backgroundColor: 'purple.100',
        borderColor: 'gray.200',
      }
    },
    _disabled: {
      backgroundColor: 'gray.50',
      borderColor: 'gray.200',
      color: 'gray.200',
      _hover: {
        backgroundColor: 'gray.50',
        borderColor: 'gray.200',
      }
    },
  },
});

const sizes = {
  sm: definePartsStyle({
    label: defineStyle({
      fontSize: 'sm',
      lineHeight: '20px',
    }),
    control: defineStyle({
      boxSize: '20px',
    }),
    icon: defineStyle({
      fontSize: '10px',
    }),
  }),
  lg: definePartsStyle({
    label: defineStyle({
      fontSize: 'md',
    }),
    control: defineStyle({
      boxSize: '25px',
    }),
    icon: defineStyle({
      fontSize: '14px',
    }),
  }),
  xl: definePartsStyle({
    label: defineStyle({
      fontSize: 'lg',
    }),
    control: defineStyle({
      boxSize: '25px',
    }),
  }),
};

const variants = {
  block: definePartsStyle({
    container: defineStyle({
      display: 'flex',
    }),
    label: defineStyle({
      flex: 1,
    }),
  }),
};

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    colorScheme: 'purple',
    size: 'lg',
  },
});
