import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';

const badgeColor = cssVar('badge-color');

const baseStyle = defineStyle({
  borderRadius: 'md',
  fontFamily: 'heading',
  fontSize: 'xs',
  px: 4,
  py: 1,
  shadow: 'none',
  textTransform: 'none',
});

const block = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    bgGradient: `linear(to-b, ${c}.200, ${c}.300)`,
    color: 'white',
    fontSize: 'md',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '2px',
    p: 0,
  };
});

const large = defineStyle({
  width: 12,
  height: 12,
});

const medium = defineStyle({
  width: 8,
  height: 8,
});

const small = defineStyle({
  width: 7,
  height: 7,
});

/* A necessary, empty definition to stop Chakra from erroring out on build. The solid variant is defined in the docs. */
const solid = defineStyle({});

const subtle = defineStyle({
  bg: badgeColor.reference,
  border: '1px solid',
  borderColor: badgeColor.reference,
  color: 'white',
});

const outline = defineStyle({
  bg: 'transparent',
  border: '1px solid',
  borderColor: badgeColor.reference,
  color: 'purple.700', //badgeColor.reference,
});

export const badgeTheme = defineStyleConfig({
  baseStyle,
  sizes: {
    small,
    medium,
    large,
  },
  variants: {
    block,
    outline,
    solid,
    subtle,
  },
  defaultProps: {
    colorScheme: 'orange',
    variant: 'solid',
  },
});
