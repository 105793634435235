import GoldplayMedium from '@@assets/fonts/Goldplay-Medium.ttf';
import GoldplayMediumIt from '@@assets/fonts/Goldplay-MediumIt.ttf';
import GoldplaySemiBold from '@@assets/fonts/Goldplay-SemiBold.ttf';
import GoldplaySemiBoldIt from '@@assets/fonts/Goldplay-SemiBoldIt.ttf';
import Inter from '@@assets/fonts/Inter-Variable.ttf';
import { Global } from '@emotion/react';

const unicodeRange =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD';

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Goldplay';
      font-style: normal;
      font-weight: 400;
      font-display: 'swap';
      src: url(${GoldplayMedium}) format('truetype');
      unicode-range: ${unicodeRange};
    }
    @font-face {
      font-family: 'Goldplay';
      font-style: italic;
      font-weight: 400;
      font-display: 'swap';
      src: url(${GoldplayMediumIt}) format('truetype');
      unicode-range: ${unicodeRange};
    }
    @font-face {
      font-family: 'Goldplay';
      font-style: normal;
      font-weight: 700;
      font-display: 'swap';
      src: url(${GoldplaySemiBold}) format('truetype');
      unicode-range: ${unicodeRange};
    }
    @font-face {
      font-family: 'Goldplay';
      font-style: italic;
      font-weight: 700;
      font-display: 'swap';
      src: url(${GoldplaySemiBoldIt}) format('truetype');
      unicode-range: ${unicodeRange};
    }
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-display: 'swap';
      font-weight: 400 700;
      src: url(${Inter}) format('truetype');
      unicode-range: ${unicodeRange};
    }
  `}
  />
);

export default Fonts;
