export const colors = {
  gray: {
    '50': '#F9F9F9',
    '100': '#EFEFEF',
    '200': '#E0DFE0',
    '300': '#C0BEC1',
    '400': '#9F9BA1',
    '500': '#7E7882',
    '600': '#5D5263',
    '700': '#4E4454',
    '800': '#3F3644',
    '900': '#1A191A',
  },
  red: {
    '50': '#FDE7E7',
    '100': '#FABCBC',
    '200': '#F79292',
    '300': '#F46767',
    '400': '#F13C3C',
    '500': '#EE1111',
    '600': '#BF0E0E',
    '700': '#8F0A0A',
    '800': '#5F0707',
    '900': '#300303',
  },
  orange: {
    '50': '#FFFCF2',
    '100': '#FFF0CC',
    '200': '#FFDE99',
    '300': '#FFD67F',
    '400': '#FFC001',
    '500': '#FFAF33',
    '600': '#FF9100',
    '700': '#FF7100',
    '800': '#FF5200',
    '900': '#FF2F00',
  },
  yellow: {
    '50': '#FFFCE5',
    '100': '#FFF8B8',
    '200': '#FFF38A',
    '300': '#FFEE5C',
    '400': '#FFEA2E',
    '500': '#FFE500',
    '600': '#CCB700',
    '700': '#998900',
    '800': '#665C00',
    '900': '#332E00',
  },
  green: {
    '50': '#F3FFE5',
    '100': '#DCFFB8',
    '200': '#C6FF8A',
    '300': '#AFFF5C',
    '400': '#99FF2E',
    '500': '#66C700',
    '600': '#4E9900',
    '700': '#346600',
    '800': '#264A00',
    '900': '#1A3300',
  },
  blue: {
    '50': '#E5F4FF',
    '100': '#B8E0FF',
    '200': '#8ACCFF',
    '300': '#5CB8FF',
    '400': '#2EA4FF',
    '500': '#0090FF',
    '600': '#0073CC',
    '700': '#005699',
    '800': '#003A66',
    '900': '#001D33',
  },
  purple: {
    '50': '#FAF6FB',
    '100': '#EDDFF6',
    '200': '#C599E1',
    '300': '#A863D3',
    '400': '#7D13BF',
    '500': '#6F11AE',
    '600': '#610F9D',
    '700': '#5A0E95',
    '800': '#530C8D',
    '900': '#37086B',
  },
  pink: {
    '50': '#FEECFC',
    '100': '#FAD2F6',
    '200': '#F7B7F0',
    '300': '#F39BE9',
    '400': '#F07FE3',
    '500': '#ED65DE',
    '600': '#CD4ABE',
    '700': '#A33196',
    '800': '#6E1A64',
    '900': '#461340',
  },
  fadedpurple: {
    '50': '#F9F6FB',
    '100': '#FAF6FB',
    '200': '#EDDFF6',
    '300': '#C599E1',
    '400': '#A863D3',
    '500': '#7D13BF',
    '600': '#6F11AE',
    '700': '#610F9D',
    '800': '#5A0E95',
    '900': '#530C8D',
  },
  // fadedpurple: {
  //   '50': '#FFF',
  //   '100': '#F9F6FB',
  //   '200': '#FAF6FB',
  //   '300': '#EDDFF6',
  //   '400': '#C599E1',
  //   '500': '#A863D3',
  //   '600': '#7D13BF',
  //   '700': '#6F11AE',
  //   '800': '#610F9D',
  //   '900': '#5A0E95',
  // },
  purpleGrad:
    'linear-gradient(198deg, var(--chakra-colors-purple-900) 10%, var(--chakra-colors-purple-400) 70%, #F6B0EE 120%)',
  pinkGrad:
    'linear-gradient(120deg, var(--chakra-colors-white) 20%, #FDEBFB 60%)',
};
