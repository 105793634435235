import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

// This theme file is currently empty but will be filled out during a later story.

const baseStyle = definePartsStyle({});

const sizes = {};

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {},
});
