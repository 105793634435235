import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  color: 'gray.600',
  fontFamily: 'heading',
  fontSize: 'sm',
  fontWeight: 'bold',
  marginEnd: 3,
  mb: 1,
  transitionProperty: 'common',
  transitionDuration: 'normal',
  opacity: 1,

  _disabled: {
    opacity: 0.4,
  },

  _focus: {
    color: 'purple.500',
  },

  _dark: {
    color: 'white',
  },
});

export const formLabelTheme = defineStyleConfig({
  baseStyle,
});
