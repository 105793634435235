import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const purple = definePartsStyle({
  dialog: {
    borderRadius: '2xl',
    px: 8,
    py: 4,
  },
  header: {
    fontFamily: 'heading',
    pt: 2,
    pb: 4,
    px: 0,
    borderBottom: '1px solid',
    borderColor: 'fadedpurple.300',
  },
  body: {
    py: 5,
    px: 0,
  },
  footer: {
    px: 0,
    pb: 0,
    borderTop: '1px solid',
    borderColor: 'fadedpurple.300',
  },
  overlay: {
    bg: 'rgba(50, 15, 103, 0.85)',
  },
  closeButton: {
    color: 'purple.200',
    right: 7,
    top: 6,
    fontSize: '14px',
  },
});

const xxl = defineStyle({
  width: '720px',
});

const sizes = {
  xxl: definePartsStyle({ dialog: xxl }),
};

export const modalTheme = defineMultiStyleConfig({
  variants: { purple },
  sizes,
  defaultProps: {
    variant: 'purple',
  },
});
