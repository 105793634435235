import { ApiProvider } from '@@api/ApiProvider';
import { AuthProvider } from '@@auth/AuthProvider';
import { ConfirmationProvider } from '@@dialogs/confirmation';
import { SignedConfirmationProvider } from '@@dialogs/signedConfirmation';
import { Router } from '@@routing/Router';
import Fonts from '@@theme/Fonts';
import { ThemeProvider } from '@@theme/ThemeProvider';
import React from 'react';

export const App: React.FC = () => {
  return (
    <ThemeProvider>
      <Fonts />
      <ConfirmationProvider>
        <SignedConfirmationProvider>
          <ApiProvider>
            <AuthProvider>
              <Router />
            </AuthProvider>
          </ApiProvider>
        </SignedConfirmationProvider>
      </ConfirmationProvider>
    </ThemeProvider>
  );
};
