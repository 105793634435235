import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { inputTheme } from './Input';

const baseStyle = defineStyle({
  borderRadius: 'md',
  border: '1px solid',
  borderColor: 'purple.100',
});

const variants = {
  outline: { ...inputTheme.variants?.outline.field },
};

export const textareaTheme = defineStyleConfig({
  baseStyle,
  variants,
});
