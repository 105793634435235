import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';

import { colors } from '@@theme/colors';
import { styles } from '@@theme/styles';

import { accordionTheme } from '@@theme/Accordion';
import { buttonTheme } from '@@theme/Buttons';
import { cardTheme } from '@@theme/Card';
import { checkboxTheme } from '@@theme/Checkbox';
import { inputTheme } from '@@theme/Input';

import { selectTheme } from '@@theme/Select';
import { formTheme } from './FormControl';
import { formErrorTheme } from './FormErrorMessage';
import { formLabelTheme } from './FormLabel';
import { linkTheme } from './Link';
import { modalTheme } from './Modal';
import { tableTheme } from './Table';

import { tabsTheme } from '@@theme/Tabs';
import { tagTheme } from '@@theme/Tag';
import { dividerTheme } from '@@ui/Divider';
import { badgeTheme } from './Badge';
import { textareaTheme } from './Textarea';
import { tabContentTheme } from './TabContent';

export const theme = extendTheme({
  styles,
  fonts: {
    body: `'Inter', Helvetica, system-ui, sans-serif`,
    heading: `'Goldplay', system-ui, sans-serif`,
  },
  // Theme overrides go here
  colors,
  fontSizes: {
    xxs: '0.625rem',
    xxxs: '0.5rem',
  },
  semanticTokens: {
    colors: {
      error: 'red.400',
      success: 'green.500',
    },
  },
  radii: {
    '2xl': '20px',
  },
  shadows: {
    md: '4px 4px 16px rgba(0, 0, 0, 0.14)',
    '2xl': '6px 6px 30px 0px rgba(168, 99, 211, 0.25)',
    'purple-sm': '2px 2px 8px rgba(55, 8, 107, 0.5)',
    'purple-md': '2px 2px 20px rgba(197, 153, 225, 0.5)',
    'gold-md': '2px 2px 20px rgba(255, 113, 0, 0.25)',
  },
  zIndices: {
    headerBar: 3,
    navigationMenu: 2,
  },
  components: {
    Accordion: accordionTheme,
    Badge: badgeTheme,
    Button: buttonTheme,
    Card: cardTheme,
    Checkbox: checkboxTheme,
    Divider: dividerTheme,
    Form: formTheme,
    FormErrorMessage: formErrorTheme,
    FormLabel: formLabelTheme,
    Input: inputTheme,
    Link: linkTheme,
    Modal: modalTheme,
    Select: selectTheme,
    Table: tableTheme,
    Tabs: tabsTheme,
    Tag: tagTheme,
    Textarea: textareaTheme,
    TabContent: tabContentTheme,
  },
});

/**
 * Customizes the Chakra theme and provides it via context.
 */
export const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return <ChakraProvider theme={theme} {...props} />;
};
