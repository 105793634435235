import { defineStyleConfig } from '@chakra-ui/react';

export const tabContentTheme = defineStyleConfig({
  // The styles for the tab content
  baseStyle: {
    px: 4,
    'h1, h2, h3, h4, h5, h6': {
      fontFamily: 'heading',
      fontSize: 'xl',
      fontWeight: 'bold',
      mb: 2,
    },
    'h2, h3, h4, h5, h6': {
      fontSize: 'lg',
    },
    p: {
      mb: 4,
    },
    ul: {
      listStyleType: 'disc',
      marginLeft: '20px',
      paddingLeft: 0,
    },
    ol: {
      listStyleType: 'decimal',
      marginLeft: '20px',
      paddingLeft: 0,
    },
    'ul li, ol li': {
      marginBottom: '5px',
    },
    a: {
      color: 'blue.500',
      display: 'inline-block',
      textDecoration: 'none',
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
});
