import {
  StyleFunctionProps,
  theme as defaultTheme,
  defineStyleConfig,
} from '@chakra-ui/react';
import _ from 'lodash';

export const tableTheme = defineStyleConfig({
  baseStyle: {
    th: {
      textTransform: 'none',
    },
  },
  variants: {
    autotable: (props: StyleFunctionProps) =>
      _.merge({}, defaultTheme.components.Table.variants?.striped(props), {
        table: {
          bg: 'gray.50',
        },
        thead: {
          bg: 'black',
          color: 'white',
          fontWeight: 'bold',
          '*': {
            color: 'white',
          },
        },
        th: {
          color: 'white',
        },
      }),
    striped: {
      table: {
        bg: 'gray.50',
      },
    },
    simple: {
      th: {
        borderBottomColor: 'gray.500',
      },
      td: {
        borderBottomColor: 'gray.300',
      },
    },
    dataTable: {
      thead: {
        fontFamily: 'heading',
      },
      th: {
        color: 'white',
        py: 2,

        '&:first-of-type': {
          pl: 7,
        },
        '&:last-of-type': {
          pr: 7,
        },
      },
      td: {
        bg: 'white',
        borderTop: '1px solid',
        borderTopColor: 'gray.200',
        p: 3,

        '&:first-of-type': {
          pl: 4,
        },
        '&:last-of-type': {
          pr: 4,
        },
      },
    },
  },
});
