import { formAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const formControlColor = cssVar('form-control-color');

const baseStyleRequiredIndicator = defineStyle({
  marginStart: '1',
  [formControlColor.variable]: 'colors.red.500',
  _dark: {
    [formControlColor.variable]: 'colors.red.300',
  },
  color: formControlColor.reference,
});

const baseStyleHelperText = defineStyle({
  mt: '1',
  [formControlColor.variable]: 'colors.gray.600',
  _dark: {
    [formControlColor.variable]: 'colors.whiteAlpha.600',
  },
  color: formControlColor.reference,
  lineHeight: 'normal',
  fontFamily: 'body',
  fontSize: 'xs',
});

const baseStyle = definePartsStyle({
  container: {},
  requiredIndicator: baseStyleRequiredIndicator,
  helperText: baseStyleHelperText,
});

export const formTheme = defineMultiStyleConfig({
  baseStyle,
});
