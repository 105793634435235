import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const barVariant = definePartsStyle((props) => {
  const { colorScheme: c } = props; // extract colorScheme from component props

  return {
    root: {
      borderRadius: 'md',
    },
    tab: {
      bg: 'transparent',
      borderTopRadius: '4px',
      color: 'white',
      fontFamily: 'heading',
      fontWeight: 'bold',
      px: 8,

      _hover: {
        bg: 'whiteAlpha.300',
      },

      _selected: {
        bg: 'white',
        color: 'gray.900',
      },
    },
    tablist: {
      bg: `linear-gradient(90deg, #535664 0%, var(--chakra-colors-${c}-400) 100%)`,
      px: '3px',
      pt: '3px',
      borderTopRadius: 'md',
      gap: '3px',
    },
    tabpanel: {
      px: 0,
      bg: 'white',
      borderBottomRadius: 'md',
    },
  };
});

export const tabsTheme = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'purple',
  },
  variants: { bar: barVariant },
});
