import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  fontFamily: 'heading',
  gap: '1',
});

const sizes = {
  sm: defineStyle({
    height: '34px',
  }),
  lg: defineStyle({
    height: '50px',
    px: '30px',
  }),
};

const primary = defineStyle({
  bg: `linear-gradient(180deg, #7D13BF 0%, #580D92 100%)`,
  border: '1px solid',
  borderColor: 'purple.400',
  boxShadow: '4px 4px 10px 0px rgba(168, 99, 211, 0.50)',
  color: 'white',

  _disabled: {
    bg: 'purple.500',
  },

  _hover: {
    bg: `linear-gradient(180deg, #F6B0EE 0%, #7D13BF 100%)`,
    borderColor: 'purple.400',

    _disabled: {
      bg: 'purple.500',
    },
  },
});

const secondary = defineStyle({
  bg: `linear-gradient(180deg, #fff 0%, #F8F4F9 100%)`,
  border: '1px solid',
  borderColor: 'purple.100',
  boxShadow: '4px 4px 10px 0px rgba(168, 99, 211, 0.25)',
  color: 'purple.400',

  _hover: {
    bg: 'purple.300',
    borderColor: 'purple.300',
    color: 'white',
  },
});

const outline = defineStyle({
  borderColor: 'gray.300',
  color: 'gray.500',

  _hover: {
    bg: 'purple.300',
    borderColor: 'purple.300',
    color: 'white',
  },

  _dark: {
    borderColor: 'purple.300',
    bg: 'white',
    color: 'purple.600',

    _hover: {
      bg: 'purple.300',
      borderColor: 'purple.300',
      color: 'white',
    },

    _disabled: {
      borderColor: 'purple.200',
      color: 'purple.200',

      _hover: {
        bg: 'white',
      },
    },
  },
});

const ghost = defineStyle({
  color: 'purple.400',

  _hover: {
    bg: 'purple.100',
  },
});

const familyLookup = defineStyle({
  background: 'purple.300',
  borderRadius: '20px',
  color: 'white',
  justifyContent: 'left',

  _hover: {
    bg: 'purple.300',
    borderColor: 'purple.300',
    color: 'white',
  },
});

const kioskPrimary = defineStyle({
  bgGradient: 'linear-gradient(180deg, orange.400, orange.600)',
  border: '1px solid',
  borderColor: 'purple.400',
  boxShadow: '4px 4px 10px 0px #37086B88',
  color: 'black',

  _disabled: {
    bg: 'transparent',
    bgGradient: null,
    color: 'purple.300',
    borderColor: 'purple.300',
    border: '2px solid',
    boxShadow: 'none',
    opacity: 0.8,
  },

  _hover: {
    bgGradient: 'linear-gradient(180deg, #F6B0EE, orange.600)',
    color: 'white',
  },

  _active: {
    bgGradient: 'linear-gradient(180deg, #F6B0EE, orange.500)',
    color: 'black',
  },
});

const kioskSecondary = defineStyle({
  bgGradient: 'linear-gradient(180deg, purple.300, purple.400)',
  border: '1px solid',
  borderColor: 'purple.400',
  boxShadow: '4px 4px 10px 0px #37086B88',
  color: 'white',

  _disabled: {
    bg: 'transparent',
    bgGradient: null,
    color: 'purple.300',
    borderColor: 'purple.300',
    border: '2px solid',
    boxShadow: 'none',
    opacity: 0.8,
  },

  _hover: {
    bgGradient: 'linear-gradient(180deg, #F6B0EE, purple.400)',
  },
});

const kioskTertiary = defineStyle({
  bg: 'purple.700',
  color: 'purple.200',
  boxShadow: '2px 2px 0 0px #37086B88',
  fontSize: 'sm',
  padding: 8,
  borderRadius: '16px',

  _hover: {
    bg: 'purple.500',
  },
});

const kioskKeypad = defineStyle({
  bg: 'purple.300',
  fontSize: '44px',
  width: '96px',
  height: '96px',
  borderRadius: '16px',
  boxShadow: '2px 2px 0 0px #37086B88',

  _hover: {
    bg: 'white',
    color: 'purple.500',
  },
});

const frontDeskNav = defineStyle({
  bgGradient: 'linear-gradient(180deg, purple.200, purple.400)',
  border: '1px solid',
  borderColor: 'purple.300',
  borderRadius: 'lg',
  boxShadow: 'purple-sm',
  color: 'white',
  display: 'flex',
  flexFlow: 'column',
  fontFamily: 'heading',
  fontSize: 'sm',
  height: 'auto',
  padding: '2',
  minHeight: '100px',
  textAlign: 'center',
  whiteSpace: 'wrap',
  outline: '1px solid',
  outlineColor: 'purple.700',

  _hover: {
    bgGradient: 'linear-gradient(180deg, #F6B0EE, purple.400)',
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    primary,
    secondary,
    ghost,
    outline,
    familyLookup,
    kioskPrimary,
    kioskSecondary,
    kioskTertiary,
    kioskKeypad,
    frontDeskNav,
  },
  defaultProps: {
    colorScheme: 'purple',
  },
});
