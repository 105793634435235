import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const mdStyle = definePartsStyle({
  field: {
    fontSize: 'md',
    px: 3,
    py: 2,
    borderRadius: 'md',
  },
});

const outlineStyle = definePartsStyle({
  field: {
    borderColor: 'purple.300',
    bg: 'white',
    color: 'gray.900',
    fontFamily: 'heading',
    _disabled: {
      borderColor: 'gray.200',
      bg: 'gray.50',
    },
    _error: {
      borderColor: 'red.500',
    },
    _hover: {
      borderColor: 'purple.500',
      boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)',
    },
    _focus: {
      borderColor: 'purple.500',
      boxShadow: '0 0 0 1px var(--chakra-colors-purple-500)',
    },
    _focusVisible: {
      boxShadow: 'none',
    },
    _readOnly: {
      bg: 'purple.100',
      borderColor: 'white',
      boxShadow: '0 0 0 1px var(--chakra-colors-purple-300)',
      color: 'gray.900',
      _focus: {
        borderColor: 'white',
        boxShadow: '0 0 0 1px var(--chakra-colors-purple-300)',
      },
    },
  },
  addon: {
    bg: 'gray.50',
    px: 2,
    borderColor: 'gray.300',
  },
});

export const inputTheme = defineMultiStyleConfig({
  sizes: { md: mdStyle },
  variants: { outline: outlineStyle },
});
