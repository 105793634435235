import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'blue.500',
});

const primary = defineStyle({
  bg: `linear-gradient(180deg, #7D13BF 0%, #580D92 100%)`,
  border: '1px solid',
  borderColor: 'purple.400',
  borderRadius: 'md',
  boxShadow: '4px 4px 10px 0px rgba(168, 99, 211, 0.50)',
  color: 'white',
  display: 'inline-block',
  fontFamily: 'heading',
  fontWeight: 'bold',
  px: 4,
  py: 2,
  textAlign: 'center',

  _hover: {
    bg: `linear-gradient(180deg, #F6B0EE 0%, #7D13BF 100%)`,
    borderColor: 'purple.400',
    textDecoration: 'none',
  },
});

const secondary = defineStyle({
  bg: `linear-gradient(180deg, #fff 0%, #F8F4F9 100%)`,
  border: '1px solid',
  borderColor: 'purple.100',
  borderRadius: 'md',
  boxShadow: '4px 4px 10px 0px rgba(168, 99, 211, 0.25)',
  color: 'purple.400',
  display: 'inline-block',
  fontFamily: 'heading',
  fontWeight: 'bold',
  px: 4,
  py: 2,
  textAlign: 'center',

  _hover: {
    bg: 'purple.300',
    borderColor: 'purple.300',
    boxShadow: '4px 4px 10px 0px rgba(168, 99, 211, 0.35)',
    color: 'white',
    textDecoration: 'none',
  },
});

export const linkTheme = defineStyleConfig({
  baseStyle,
  variants: {
    primary,
    secondary,
  },
});
