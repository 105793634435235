import hexes from '@@assets/hexes.svg';

export const styles = {
  global: {
    body: {
      bg: '#F8F4F9',
      color: 'gray.900',
      fontFamily: 'body',
      fontSize: 'md',
      lineHeight: 'tall',
      minHeight: '100vh',
    },
    '#root': {
      minHeight: '100vh',
    },
    '.bg-yellow-gradient': {
      bg: 'linear-gradient(152deg, #F9F5FA 63.61%, #FFEFC0 100%)',
    },
    '.bg-purple-gradient': {
      bg: 'linear-gradient(152deg, #F9F5FA 63.61%, #FFEFC0 100%)',
    },
    '.bg-hexes': {
      position: 'relative',
      '::after': {
        content: '""',
        position: 'absolute',
        bg: `url(${hexes})`,
        backgroundPosition: '100% 0%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        opacity: 0.7,
        w: '100%',
        h: '100%',
        minW: '1080px',
        minH: '700px',
        bottom: 0,
        right: 0,
        zIndex: 0,

        '@media (max-width: 899px)': {
          backgroundSize: 'contain',
        },

        '@media print': {
          display: 'none !important',
        },
      },
      '> *': {
        position: 'relative',
        zIndex: 1,
      },
    },
    '.bg-faded-hexes': {
      '::after': {
        opacity: 0.3,
      },
    },
    '.filter-shadow': {
      filter: 'drop-shadow(10px 10px 14px rgba(197, 153, 225, 0.95))',
    },
    '@media print': {
      body: {
        bg: 'white !important',
      },
      '.print-hidden': {
        display: 'none !important',
      },
      '.print-flush': {
        m: '0 !important',
        p: '0 !important',
        w: 'auto !important',
        h: 'auto !important',
        minW: 'unset !important',
        minH: 'unset !important',
        shadow: 'none !important',
        overflow: 'visible !important',
      },
      '.print-no-bg': {
        bg: 'none !important',
      },
      '.print-column': {
        flexFlow: 'column !important',
        gap: '3rem',
      },
    },
    '.js-pdf-page img': {
      display: 'inline', // JsPDF Fix, where 'block' display causes text to be shifted down 0.25em
    },
    '.visually-hidden': {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 0,
      margin: 0,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: '1px',
      whiteSpace: 'nowrap',
    },
    '.parent-portal-container': {
      '@media print': {
        marginLeft: '0 !important',
      },
    },
    '.text-wrap': {
      textWrap: 'wrap',
    },
  },
};
